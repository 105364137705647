@media (max-aspect-ratio: 9/16), (max-aspect-ratio: 9/18), (max-aspect-ratio: 9/21), (max-aspect-ratio: 3/4), (max-aspect-ratio: 9/16) {
  .advantages {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    height: calc(187.5px * 5);
    margin: 46px auto 0;
    width: max-content;
  }


}
