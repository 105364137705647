.blob {
  position: absolute;
  right: -30%;
  top: 75%;
  transform: translateY(-75%);
  @media (min-width: 1024px) {
    right: -50%;

    top: 50%;
    transform: translateY(-50%);
  }
}

.section {
  position: relative;
  width: 100%;
  padding: 97.5px 0;

  @media (min-width: 1024px) {
    padding: 195px 0;
  }

  .container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    max-width: 1005px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1024px) {
      justify-content: space-between;
      flex-direction: row;
    }

    .left {
      margin-bottom: 37.5px;
      width: 100%;
      @media (min-width: 1024px) {
        max-width: 40%;
        margin-bottom: 0;
      }

      .tag {
        font-size: 11.25px;
        font-weight: 600;
        margin-bottom: 22.5px;
        padding: 5.25px 22.5px;
        background: rgba(#ff781d, 0.4);
        width: fit-content;
        border-radius: 11.25px;
      }

      .title {
        font-size: 22.5px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 30px;
        color: #5d5d5d;
        max-width: 420px;
      }

      .desc {
        max-width: 420px;
        font-size: 11.25px;
        font-weight: 600;
        line-height: normal;
        color: #000;
        margin-bottom: 50px;
      }

      .link {
        position: relative;
        font-size: 11.25px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: .7875px;
        color: #000;

        &::after {
          content: '';
          width: 40%;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          height: 1.5px;
          background: #ff781d;
          transition: width 0.3s ease-in-out;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }

    .right {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 22.5px;

      @media (min-width: 540px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 37.5px;
        .aboutItem:nth-child(2) {
          top: 22.5px;
        }

        .aboutItem:nth-child(4) {
          top: 22.5px;
        }
      }

      @media (min-width: 1024px) {
        max-width: 48%;

        .aboutItem:nth-child(2) {
          top: 15px;
        }
        .aboutItem:nth-child(3) {
          left: -52.5px;
        }
        .aboutItem:nth-child(4) {
          top: 15px;
          left: -52.5px;
        }
      }
    }
  }
}

.aboutItem {
  position: relative;
  width: 100%;
  padding: 22.5px 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 7.5px 27px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  @media (min-width: 540px) {
    width: auto;
  }

  img {
    display: block;
    margin-bottom: 10px;
  }

  .text {
    max-width: 97.5px;
    text-align: center;
    font-size: 11.25px;
    font-weight: 600;
    line-height: normal;
    color: #5d5d5d;
  }
}
