
.advantages {
  margin-top: 117px;
  width: 100%;
  background-color: #fff;
  height: 187.5px;
  border-radius: 15px;
  box-shadow: 0 7.5px 27px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  position: relative;
  z-index: -1;

  > div {
    display: grid;
    height: 153px;
    width: 198px;
    justify-items: center;
    margin-top: 15px;
    padding: 0 15px;

    > div {
      > .advantages__item-title {
        font-size: 15px;
        line-height: 21.75px;
        font-weight: 600;
        color: var(--orange-durk);
        text-transform: uppercase;
      }
    }

  }
}

.advantages__img {
  position: absolute;
  opacity: 0;
}

.advantages__bg {
  position: absolute;
  z-index: -1;
}


.advantages__item:hover {
  position: relative;
  z-index: 1;
  padding: 0 0;
  margin: 0 !important;

  > p, div, svg, h3 {
    color: #fff !important;
    stroke: #fff !important;
  }

  > div {
    > .advantages__img {
      opacity: 1;
      display: block;
      margin: 11.25px auto 0;
    }

    > .advantages__bg {
      width: 225px;
      height: 228.3px;
      top: 50%;
      border-radius: 15px;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--orange-durk);
    }
  }
}


