@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
	scroll-behavior: smooth;

	font-family: 'CodeNext-Trial', sans-serif;
}

@font-face {
	font-family: 'CodeNext-Trial';
	font-style: normal;
	font-weight: normal;
	src: url('../assets/fonts/CodeNext-Trial-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'CodeNext-Trial';
	font-style: normal;
	font-weight: bold;
	src: url('../assets/fonts/CodeNext-Trial-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'CodeNext-Trial';
	font-style: normal;
	font-weight: 200;
	src: url('../assets/fonts/CodeNext-Trial-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'CodeNext-Trial';
	font-style: normal;
	font-weight: 600;
	src: url('../assets/fonts/CodeNext-Trial-SemiBold.ttf') format('truetype');
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
	display: block;
}

.text-center {
	text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: inherit;
	font-weight: inherit;
}

ul,
ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img,
svg {
	max-width: 100%;
	height: auto;
}

address {
	font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
}

input,
select,
textarea {
	padding: 15px 10.5px;
	border-radius: 15px;
	font-size: 11.25px;
	font-weight: normal;
	width: 100%;
	margin-top: 15px;
	background-color: var(--light-bg);
	color: #000000 !important;
}

*::placeholder,
option {
	color: #000000 !important;
	font-weight: normal;
	font-size: 11.25px;
	text-transform: initial;
}

textarea {
	resize: none;
	outline: 0;
	border: 0;
	box-shadow: none;
	height: 75px;
}

input::-ms-clear {
	display: none;
}

button,
input[type='submit'] {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	background: none;
	cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
	outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}

html {
	max-width: 990px;
	margin: 0 auto;
}

header {
	padding-top: 20px;
	width: 100%;
	justify-content: space-between;
}

/*header div:first-child {*/
/*    width: 74.24%;*/
/*}*/

.d-flex {
	display: flex;
}

.just-ct {
	justify-content: space-between;
}

@media screen and (min-width: 2400px) and (min-height: 1100px) {
	html {
		max-width: calc(990px * 1.5);
	}
}

@media screen and (min-width: 3700px) and (min-height: 1800px) {
	html {
		max-width: calc(990px * 2);
	}
}

:root {
	--descrip-text: #cfcfcf;
	--orange: #ff781d;
	--orange-durk: #e45c00;
	--light-bg: #f3f1f0;
	--desc-black: #5d5d5d;
	--eee: #e6e6e6;
}

.bg_header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	background-image: url('../assets/images/Group-53.webp');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100vw;
	height: 675px;
}

.contact-menu {
	display: grid;
	row-gap: 22.5px;
}

.contact-menu div {
	display: flex;
}

.contact-menu div *:not(:last-child) {
	margin-right: 15px;
}

.title-block {
	display: grid;
	justify-items: center;
}

.site-title {
	text-transform: uppercase;
	font-size: 37.5px;
	font-weight: bold;
	color: #fff;
	text-align: center;
}

.site-title-desc {
	font-size: 22.5px;
	font-weight: 200;
	text-transform: uppercase;
	text-align: center;

	color: var(--descrip-text);
}

nav *:hover {
	cursor: pointer;
}

.link {
	font-size: 18.75px;
	font-weight: normal;
	color: #fff;
	text-transform: uppercase;
}

.mini-link {
	font-size: 11.25px;
	font-weight: normal;
	color: var(--desc-black);
	text-transform: uppercase;
}

.link-desc {
	font-weight: normal;
	font-size: 11.25px;
	color: var(--descrip-text);
}

.default-btn {
	padding: 11.25px 37.5px;
	border-radius: 37.5px;
	white-space: nowrap;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
	background-color: var(--orange);
	color: #fff;
	transition: all 0.2s linear;
}

.light-btn {
	background-color: #fff;
	color: var(--orange);
}

.default-btn:hover {
	background-color: #fff;
	color: var(--orange);
}

.light-btn:hover {
	background-color: var(--orange);
	color: #fff;
}

.mini-btn {
	font-size: 12.75px;
}

.sub-title {
	font-size: 18.75px;
	font-weight: bold;
	color: var(--orange-durk);
}

.modal-menu-bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;

	&.hide {
		opacity: 0;
		visibility: hidden;
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}
}

.modal-menu-block {
	background-color: white;
	border-radius: 22.5px;
	position: fixed;
	z-index: 998;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 37px 60px;
	box-sizing: border-box;
	width: 520px;
	transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

	&.hide {
		opacity: 0;
		visibility: hidden;
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}
}

.close {
	position: absolute;
	top: 18px;
	right: 22.5px;
	z-index: 9999;
}

.white {
	color: #fff;
}

.black {
	color: #000;
}

.eee {
	color: var(--eee);
}

.grey {
	color: #444040;
}

html {
	overflow-x: hidden;
}

.swiper-pagination-bullet {
	background-color: transparent !important;
	padding: 4px !important;
	box-shadow: 0 0 0 1.5px var(--desc-black), 0 0 0 2px var(--light-bg) inset !important;
}

.swiper-pagination-bullet-active {
	background-color: var(--orange) !important;
	box-shadow: 0 0 0 1.5px var(--orange), 0 0 0 2px var(--light-bg) inset !important;
}
