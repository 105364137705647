.offer {
  width: calc(500px * 0.75);
  height: calc(90px * 0.75);;
  margin-left: 105px;
  border-radius: 10px;
  display: flex; /* Use flexbox for vertical alignment */
  align-items: center; /* Center vertically */
  background-image: url("../assets/images/photo_2023-09-02_09-43-23_1.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 0 0 20px;
}